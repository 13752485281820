export const MFA_METHOD_EMAIL = 0
export const MFA_METHOD_SMS = 1
export const MFA_METHOD_APP = 2

export const STAFF_ACTIVE = 0
export const STAFF_INACTIVE = 99

export const MEMBER_ACTIVE = 0
export const MEMBER_INACTIVE = 99

export const QUEST_ACTIVE = 0
export const QUEST_INACTIVE = 99

export const QCONT_ACTIVE = 0;
export const QCONT_INACTIVE = 99;

export const CAL_ACTIVE = 0;
export const CAL_INACTIVE = 99;

export const DOC_ACTIVE = 0;
export const DOC_INACTIVE = 99;

export const PROV_ACTIVE = 0;
export const PROV_INACTIVE = 99;

export const HOME_ACTIVE = 0;
export const HOME_INACTIVE = 99;

export const UPLOAD_DOC = 0
export const UPLOAD_IMG = 1

export const REQ_ADD_USER = 0
export const REQ_DELETE_USER = 1  

export const DOC_VISIBLE = 0
export const DOC_HIDDEN = 1  

export const TABLE_DOWNLOAD = 1  

export const FILING_UNLOCKED = 0  
export const FILING_LOCKED = 1  

export const PROG_RIVAP = 3
export const PROG_CHARI = 6

export const ZERO_LIVES = 1  

export const RATE_TRAINING = 1

export const USER_ACTIVE = 0
export const USER_INACTIVE = 99

export const PAYER_ACTIVE = 0
export const PAYER_INACTIVE = 99

export const FILE_ACTIVE = 0
export const FILE_INACTIVE = 99

export const ADJUST_DEBIT = 0
export const ADJUST_CREDIT = 1

export const REQUEST_REPORTING = 0
export const REQUEST_FILING = 1

export const CAL_FILE_AGENDA = 1
export const CAL_FILE_MINUTES = 2
export const CAL_FILE_MATERIAL = 3

export const NOTIF_MODE_PREVIEW = 0
export const NOTIF_MODE_SEND = 1

export const PROG_MVB = 2
export const PROG_WAPAL = 5

export const USERQ_ESCALATE = 0
export const USERQ_ACTIVE = 0
export const USERQ_INACTIVE = 99

export const ZERO_ACTIVE = 1
export const ZERO_INACTIVE = 0

export const GROUP_PAYERS = 1;
export const GROUP_KVUSERS = 2;
export const GROUP_NOTIFICATIONS = 3;

export const MAIL_SEND = 2;

export const FILING_ACTIVE = 0;
export const FILING_INACTIVE = 99;

export const FILING_PENDING_UPLOAD = 0;
export const FILING_UPLOADED = 1;

export const QB_CONNECT_ACTIVE = 0;
export const QB_CONNECT_INACTIVE = 99;

export const SYNCT_AUTO = 0;
export const SYNCT_MANUAL = 1;

export const SYNC_INCOMPLETE = 0;
export const SYNC_COMPLETE = 1;

export const TRIGGER_ERROR = 1;

export const UPLOAD_FAILURE = 0;
export const UPLOAD_SUCCESS = 1;

export const QB_AUTH_SUCCESS = 0; 
export const QB_AUTH_FAILURE = 1;
export const QB_AUTH_INITIAL_SUCCESS = 2;

export const QB_PAYER_INSERT = 0; 
export const QB_PAYER_UPDATE = 1;

export const SYNC_PAYER = 0;
export const SYNC_RATE = 1;

export enum PayerPreview {
    QB_PAYER_INSERT = 0, 
    QB_PAYER_UPDATE = 1
}
export const MONTH_JAN = 1;

export const RATE_MULTIYEAR = 1;