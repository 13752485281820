import confetti from 'canvas-confetti';
import bootstrap, { Tab } from 'bootstrap';
declare global {
	type Timeout = ReturnType<typeof setTimeout>
	type $ = JQuery<HTMLElement>
	interface JQuery {
		tableHeaders(this: $, name: string[]): $
		doubleScroll(this: $, userOptions?: doubleScrollParams): $
	}

	type CustomInput = {
		$: $,
		source: string,
	}
	type CustomForm = CustomInput[]
}

$.fn.tableHeaders = function (names) {
	const $table = $(this)
	$table.find('thead').remove()
	const html = names.map(name => `<th>${name}</th>`).join('')
	$table.prepend(`<thead><tr>${html}</tr></thead>`)
	return $table
}

type doubleScrollParams = {
	$insertBefore?: $
	contentElement?: $
	scrollCss?: any
	contentCss?: any
	onlyIfScroll?: boolean
	resetOnWindowResize?: boolean
	timeToWaitForResize?: number
}
$.fn.doubleScroll = function (userOptions) {
	//Merge user options with default options
	const options: doubleScrollParams = {
		$insertBefore: undefined, // Insert second scroll bar before this element, if not specified main element will be used

		contentElement: undefined, // Widest element, if not specified first child element will be used
		scrollCss: {
			'overflow-x': 'auto',
			'overflow-y': 'hidden',
			'height': '20px'
		},
		contentCss: {
			'overflow-x': 'auto',
			'overflow-y': 'hidden'
		},
		onlyIfScroll: true, // top scrollbar is not shown if the bottom one is not present
		resetOnWindowResize: true, // recompute the top ScrollBar requirements when the window is resized
		timeToWaitForResize: 30, // wait for the last update event (usefull when browser fire resize event constantly during ressing)

		...userOptions,
	}

	const internalOptions = {
		topScrollBarMarkup: '<div class="doubleScroll-scroll-wrapper"><div class="doubleScroll-scroll"></div></div>',
		topScrollBarInnerSelector: '.doubleScroll-scroll',
	}

	const showScrollBar = ($self: $, $topScrollBar: $ | null = null) => {

		// add div that will act as an upper scroll only if not already added to the DOM
		if (!$topScrollBar) {
			const $insertBefore = options.$insertBefore || $self

			// creating the scrollbar
			// added before in the DOM
			$topScrollBar = $(internalOptions.topScrollBarMarkup)
			$insertBefore.before($topScrollBar)

			// apply the css
			$topScrollBar.css(options.scrollCss)
			$(internalOptions.topScrollBarInnerSelector).css({ height: '20px' })
			$self.css(options.contentCss)

			let scrolling = false

			// bind upper scroll to bottom scroll
			$topScrollBar.on('scroll.doubleScroll', () => {
				if (scrolling) {
					scrolling = false
					return
				}
				scrolling = true
				$self.scrollLeft($topScrollBar!.scrollLeft() || 0)
			})

			// bind bottom scroll to upper scroll
			$self.on('scroll.doubleScroll', () => {
				if (scrolling) {
					scrolling = false
					return
				}
				scrolling = true
				$topScrollBar!.scrollLeft($self.scrollLeft() || 0)
			})
		}

		const hideScroll = options.onlyIfScroll && $self.get(0)!.scrollWidth <= Math.round($self.width() || 0)
		if (hideScroll) $topScrollBar.hide()
		else $topScrollBar.show()

		// find the content element (should be the widest one)	
		let $contentElement: $
		if (options.contentElement !== undefined && $self.find(options.contentElement).length !== 0) {
			$contentElement = $self.find(options.contentElement)
		}
		else {
			$contentElement = $self.find('>:first-child')
		}

		// set the width of the wrappers
		$topScrollBar.add($topScrollBar.find(internalOptions.topScrollBarInnerSelector)).width($contentElement.outerWidth() || 0)
		$topScrollBar.width($self.width() || 0)
		$topScrollBar.scrollLeft($self.scrollLeft() || 0)

		return $topScrollBar
	}

	return this.each((_, element) => {

		const $self = $(element)

		const $topScrollBar = showScrollBar($self)

		// bind the resize handler 
		// do it once
		if (options.resetOnWindowResize) {
			let timeout: Timeout | null = null
			$(window).on('resize.doubleScroll', () => {
				// adding/removing/replacing the scrollbar might resize the window
				// so the resizing flag will avoid the infinite loop here...
				if (timeout) clearTimeout(timeout)
				timeout = setTimeout(() => showScrollBar($self, $topScrollBar), options.timeToWaitForResize)
			})
		}
	})
}

/** Fetch post wrapper that works with PHP $_POST and throws responses that don't have {rc: 'OK'} */
export const fetchPost = async (url: string, postData?: object | FormData) => {
	let requestBody: RequestInit['body'] = null;
	const headers: RequestInit['headers'] = {
		Accept: 'application/json',
		'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
	};
	if (postData instanceof FormData) {
		requestBody = postData;
	} else if (postData !== undefined) {
		requestBody = JSON.stringify(postData);
		headers['Content-Type'] = 'application/json';
	}
	const response = await fetch(url, {
		method: 'POST',
		headers,
		body: requestBody,
	});
	const json = await response.json();
	// if (json.hasOwnProperty('rc') && json.rc !== 'OK') throw json;
	return json;
};

type setCollapseParams = {
	$btn: $
	$target: $
	duration?: number
}

export const setCollapse = ({ $btn, $target, duration = 350 } : setCollapseParams) => {
	const show = () => {
		$target.slideDown(duration, () => {
			$btn.find('svg').css('transform', 'rotate(180deg)');
		});
	};
	const hide = () => {
		$target.slideUp(duration, () => {
			$btn.find('svg').css('transform', '');
		});
	};
	$btn.on('click', () => {
		if ($target.css('display') === 'none') show();
		else hide();
	});
};

type collapseClearParams = setCollapseParams & {
	$clear?: $
};
export const collapseClear = ({ $btn, $target, $clear=null, duration = 350 } : collapseClearParams) => {
	const show = () => $target.slideDown(duration)
	const hide = () => $target.slideUp(duration)
	$btn.on('click', () => {
		if ($target.css('display') === 'none') show()
		else hide()
		if($clear) $clear.val('').trigger('chosen:updated') //clear inputs
	});
};

export const validateEmail = (email) => {
	return email.match(
		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	);
};

type BootstrapFlavor = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
export const displayNotification = (msg: string, background: BootstrapFlavor = 'success') => {
	const $notifications = $('.notifications').length ? $('.notifications') : $('<div class="notifications"></div>').prependTo($('body'))
	const $notification = $(`
	  <div class="notification alert alert-dismissible alert-${background}" role="alert">
		<span>${msg}</span>
		<button type="button" class="btn-close" data-bs-dismiss="alert"></button>
	  </div>
	`)
	$notification.appendTo($notifications)
	setTimeout(() => $notification.fadeOut(500, () => $notification.remove()), 2000)
}

export const setAll = (obj: object, val) => Object.keys(obj).forEach(k => obj[k] = val);

export const post = (path: string, parameters: object | null = null, target: string = null) => {
	parameters = {
		...parameters,
		'_token': $('meta[name="csrf-token"]').attr('content')
	}

	const $form = $('<form></form>')

	$form.attr('method', 'post')
	$form.attr('action', path)
	if (target != null) $form.attr('target', target)

	for (const [key, value] of Object.entries(parameters)) {
		const $input = $('<input></input>')

		$input.attr('type', 'hidden')
		$input.attr('name', key)
		$input.attr('value', value)

		$form.append($input)
	}

	$(document.body).append($form)
	$form.trigger('submit')
}

export const print_this = (config) => {
	try {
		let printwin = window.open('');
		printwin.document.body.style.pointerEvents = "none";

		let $head = $('head').clone();
		$head.find('title').html(config.title + ' | KidsVax');
		setTimeout(function () {
			printwin.document.write($head.html());
			let $content = config.callback();
			printwin.document.write($content.html());

			setTimeout(function () {
				printwin.print();
			}, 1100);
		}, 100);

	} catch (e) {
		alert(e);
		console.log('print_this', e);
	}
}

export const htmlEsc = (text: string) => {
	const span = document.createElement('span');
	span.textContent = text;
	return span.innerHTML;
};

//For babel jsx transpiling, from: https://blog.r0b.io/post/using-jsx-without-react/
export const createElement = (tagName: string, attrs: Record<string, any> | null, ...children: (HTMLElement | string)[]) => {
	if (tagName === 'fragment') return children;
	const elem = document.createElement(tagName);
	if (attrs) {
		for (const [key, val] of Object.entries(attrs)) {
			if (key.startsWith('data-')) {
				const dataName = key.substring(5);
				elem.dataset[dataName] = val;
			} else elem[key] = val;
		}
	}
	for (const child of children) {
		if (Array.isArray(child)) elem.append(...child);
		else elem.append(child);
	}
	return elem;
};

export const createFragment = 'fragment';

export const convertPennies = (pennies: number) => {
	(pennies / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });
}

export const setTimeoutPromise = (duration: number) => new Promise((resolve) => setTimeout(resolve, duration))

type confirmDialogParams = {
	dialogTitle: string;
	bodyText: string;
	confirmText: string;
	confirmStyle: BootstrapFlavor;
	confirmFunction: () => void;
	cancelText?: string;
	cancelFunction?: () => void;
	showCancelButton?: boolean;
};

export const confirmDialog = ({ dialogTitle, bodyText, confirmText, confirmStyle, confirmFunction, cancelText = 'Cancel', cancelFunction = null, showCancelButton = true }: confirmDialogParams) => {
	$('#confirm_dialog').find('.confirm_body').html(bodyText);
	$('#confirm_dialog_label').html(dialogTitle);

	$('#confirm_dialog_actions').html('');
	const $actionBtn = $('<button type="button" class="btn"></button>');
	$actionBtn.off('click').on('click', () => {
		$actionBtn.prop('disabled', true);
		confirmFunction();
		$('#confirm_dialog').modal('hide');
	});
	$actionBtn.html(confirmText);
	$actionBtn.addClass(`btn-${confirmStyle}`);
	$actionBtn.prop('disabled', true);
	setTimeout(() => $actionBtn.prop('disabled', false), 1500);
	$('#confirm_dialog_actions').append($actionBtn);

	$('#confirm_dialog_cancel').off('click').html('').hide();
	if (showCancelButton) {
		$('#confirm_dialog_cancel')
			.show()
			.html(cancelText)
			.on('click', () => {
				if (cancelFunction) cancelFunction();
			});
	} else $('#confirm_dialog_cancel').hide();

	$('#confirm_dialog').modal('show');
};

export const getTableRowData = (table: DataTables.Api, element: HTMLElement) => table.row($(element).closest('tr')).data()

let $templates: $ | null = null
export const getTemplates = () => {
	if ($templates === null) $templates = $('<div />').append($('.template'))
	return $templates
}

type inputNumParams = {
	$input: $,
	min?: number | null,
	max?: number | null,
	integer?: boolean,
	step?: number | null,
	decimals?: number | null,
}
export const inputNum = ({ $input, min = null, max = null, integer = false, step = null, decimals = null }: inputNumParams) => {
	if (!$input || !$input.length) return
	if (step !== null) $input.attr('step', step)
	if (min !== null) $input.attr('min', min)
	if (max !== null) $input.attr('max', max)
	$input.on('change', () => {
		let newNum = $input.val() as any
		if (isNaN(newNum)) {
			$input.val('')
			return
		}
		newNum = +newNum
		if (decimals !== null) newNum = newNum.toFixed(decimals)
		if (min !== null && newNum < min) newNum = min
		if (max !== null && newNum > max) newNum = max
		if (integer) newNum = Math.floor(newNum)
		$input.val(newNum)
	})
}

export const setForm = (form: CustomForm, data: object) => {
	form.forEach(({ $, source }) => {
		if($.hasClass('chosen')) {
			$.val(data[source]).trigger('chosen:updated')
		} else {
			$.val(data[source])
		}
	})
}

type multiAgeInput = {
	age_id: number,
	name: $,
	description: $,
	ageAmount: $,
}
type MultiAgeForm = multiAgeInput[]
export const getFormByAgeId = (form: MultiAgeForm) => {
	const data = {}
	form.forEach((inputGroup) => {
		const { age_id, name, description, ageAmount } = inputGroup;
		if (!data[age_id]) {
			data[age_id] = {
				name: '',
				description: '',
				ageAmount: ''
			};
		}
		data[age_id].name = name.val()
		data[age_id].description = description.val()
		data[age_id].ageAmount = ageAmount.val()
	})
	return data
}

export const getForm = (form: CustomForm): object => {
	const data = {}
	form.forEach(({ $, source }) => data[source] = $.val())
	return data
}

export const scrollTop = () => {
	$('html, body').animate({ scrollTop: 0 }, 'fast');
}

export const scrollBottom = () => {
	$('html, body').animate({
		scrollTop: $(document).height() - $(window).height()
	}, 'fast');	  
}

export const toTab = (tab: string) => {
	$('.nav-tabs a[href="#' + tab + '"]').tab('show');
	$(window).trigger('resize')
}

//for bootstrap 5 tab constructor
export const toTabNew = (tabId: string) => {
	const tabTrigger = document.querySelector(`button[data-bs-target="#${tabId}"]`);
	if(!tabTrigger) return console.error(`Tab trigger not found for tab id: ${tabId}`)

	const bootstrapTabInstance = new Tab(tabTrigger)
	bootstrapTabInstance.show()
	window.dispatchEvent(new Event('resize'));
}

export const toPill = (tab: string) => {
	$('.nav-pills a[href="#' + tab + '"]').tab('show');
	$(window).trigger('resize')
}

export const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2
})

export const waitForElement = (selector, timeout = 1000, interval = 100) => {
	const startTime = Date.now()
	return new Promise((resolve, reject) => {
		const checkForElement = () => {
			const element = $(selector)
			if (element.length > 0) {
				resolve(element);
			} else if (Date.now() - startTime > timeout) {
				reject(new Error(`Element not found within ${timeout}ms`))
			} else {
				setTimeout(checkForElement, interval)
			}
		}
		checkForElement()
	})
}

type loadingParams = {
	loading: $,
	content: $,
}
export const toggleLoading = (params: loadingParams, action: 'show' | 'hide') => { 
	if(action === 'show') {
		params.loading.show()
		params.content.hide()
	} else {
		params.loading.hide()
		params.content.show()
	}
}

export const buttonIsLoading = ($btn: $, loading: boolean) => {
	const lang = $btn.find('span')
	const spinner = $btn.find('.spinner-border')
	if(loading) {
		lang.hide()
		spinner.show()
	} else {
		lang.show()
		spinner.hide()
	}
}

export const formatNumber = (number: number) => {
	return new Intl.NumberFormat().format(number);
}

export const showLoadingScreen = () => {
	$('#loading-screen').css('display', 'flex');
};

export const hideLoadingScreen = () => {
	$('#loading-screen').css('display', 'none');
};

export const showConfetti = () => {	
	confetti({
		particleCount: 250,
		spread: 100,
		origin: { y: 0.4 },
		zIndex: 1060
	})
}

export const highlightInput = (selector, condition) => $(selector).css('border-color', condition ? 'red' : '')
